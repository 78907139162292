body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* these styles are applied to duurgelt chart's top positioned checkboxes */
.chartLabel {
  user-select: none;
  font-weight: 500;
}

.chartCheckbox {
  width: 12px;
  display: none;
}

.chartLabel:hover {
  cursor: pointer;
}

.chartCheckbox:hover {
  cursor: pointer;
}

/*  skeleton loader styles */
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 80px;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-title {
  width: 50px;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 75%);
    stroke: hsl(200, 20%, 75%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
    stroke: hsl(200, 20%, 95%);
  }
}

/* for line skeleton */
#line line {
  fill: none;
  stroke-width: 1;
  stroke-dasharray: 1564;
  stroke-dashoffset: 1564;
  animation: line-anim 1s ease-in-out infinite;
}

@keyframes line-anim {
  0% {
    stroke: hsl(200, 20%, 75%);
  }
  to {
    stroke-dashoffset: 0;
    stroke: hsl(200, 20%, 95%);
  }
}
